import React from "react";
import Modal from "react-modal";
import { FaExclamationTriangle } from "react-icons/fa";

export default function MaintenanceModal({ isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Maintenance Modal"
      className="fixed inset-0 flex items-center justify-center p-4 z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative">
        <button
          onClick={onRequestClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex items-center space-x-6">
          {" "}
          <div className="flex flex-col items-center space-y-4">
            <FaExclamationTriangle className="text-yellow-500 text-5xl" />{" "}
          </div>
          <div className="text-gray-700">
            <h2 className="text-2xl font-bold mb-2">Payment Maintenance</h2>
            <p className="text-gray-600">
              We are currently conducting payment testing. Please try again
              later. We apologize for any inconvenience and appreciate your
              patience.
            </p>
          </div>
        </div>

        <button
          onClick={onRequestClose}
          className="mt-6 w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg"
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
