import React, { useState } from "react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { getBase64ImageSrc } from "../../../utils/imageUtils";

const AccountantCard = ({ accountant, handleSelectAccountant, loadingId }) => {
  const [image, setImage] = useState("");

  const getNameParts = (fullName) => {
    const suffixes = ["Jr", "Sr", "II", "III", "IV", "V"];
    const nameParts = fullName.split(" ");
    let lastName = "";
    let firstName = "";
    let suffix = "";

    if (suffixes.includes(nameParts[nameParts.length - 1])) {
      suffix = nameParts.pop();
    }
    lastName = nameParts.pop();
    firstName = nameParts.join(" ");

    return { lastName, firstName, suffix };
  };
  const { lastName, firstName, suffix } = getNameParts(accountant.name);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between h-[450px] w-full max-w-[300px]">
      {/* Profile Section */}
      <div className="relative flex flex-col items-center p-6 bg-gradient-to-b from-green-700 via-white to-gray-200 text-center border-b">
        <div className="relative w-24 h-24 mb-4">
          {accountant.profile_picture ? (
            <img
              src={getBase64ImageSrc(accountant.profile_picture) || null}
              alt={`${accountant.name}'s profile`}
              className="w-full h-full rounded-full border-4 border-white shadow-md"
            />
          ) : (
            <div className="w-full h-full rounded-full bg-gray-600 flex items-center justify-center border-4 border-white shadow-md">
              <span className="text-white text-3xl font-bold">
                {accountant.name.charAt(0)}
              </span>
            </div>
          )}

          {/* Level Badge */}
          {accountant.level && (
            <div className="absolute top-0 right-0 bg-green-500 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-lg">
              {accountant.level}
            </div>
          )}
        </div>

        {/* Accountant Name */}
        <div className="relative">
          <h3 className="text-lg font-bold text-green-800 mb-1 truncate max-w-full">
            {`${lastName}, ${firstName}${suffix ? ` ${suffix}` : ""}`.length >
            28
              ? `${lastName}, ${firstName}${suffix ? ` ${suffix}` : ""}`.slice(
                  0,
                  28
                ) + "..."
              : `${lastName}, ${firstName}${suffix ? ` ${suffix}` : ""}`}
          </h3>
        </div>

        {/* Qualifications */}
        {accountant.qualifications ? (
          <div className="relative group">
            <p className="mt-1 bg-green-600 text-white hover:bg-green-700 text-xs font-medium px-3 py-1 rounded-full max-w-full truncate">
              {accountant.qualifications.length > 28
                ? `${accountant.qualifications.slice(0, 28)}...`
                : accountant.qualifications}
            </p>
            {/* Tooltip */}
            <div className="absolute z-10 hidden group-hover:flex w-max max-w-xs bg-black text-white text-xs rounded-md p-2 left-1/2 transform -translate-x-1/2 mt-2">
              {accountant.qualifications}
            </div>
          </div>
        ) : (
          <p className="mt-1 bg-gray-300 text-gray-700 text-xs font-medium px-3 py-1 rounded-full">
            No Accreditation
          </p>
        )}
      </div>

      {/* Accountant Info */}
      <div className="p-5 space-y-4 flex flex-col grow justify-between">
        <div className="text-center space-y-2">
          {/* Phone */}
          <div className="text-gray-600 text-sm flex justify-center items-center space-x-2">
            {accountant.phone ? (
              <>
                <FaPhoneAlt />
                <span>{accountant.phone}</span>
              </>
            ) : (
              <span>Phone not available</span>
            )}
          </div>

          {/* Email */}
          <div className="text-gray-600 text-sm flex justify-center items-center space-x-2">
            <FaEnvelope />
            <span>{accountant.user_email || "No email provided"}</span>
          </div>
        </div>

        {/* Price */}
        <div className="flex justify-center items-center">
          <div className="text-lg font-bold text-gray-900">
            {accountant.price
              ? `₱${accountant.price}/hr`
              : "Rate not available"}
          </div>
        </div>

        {/* Button */}
        <div className="mt-4">
          <button
            onClick={() =>
              handleSelectAccountant(
                accountant.accountant_id,
                accountant.profile_picture
              )
            }
            className={`w-full py-3 rounded-lg text-white font-semibold text-lg transition-all duration-200 ${
              loadingId === accountant.accountant_id
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-700 focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
            }`}
            disabled={loadingId === accountant.accountant_id}
          >
            {loadingId === accountant.accountant_id
              ? "Loading..."
              : "View Profile"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountantCard;
