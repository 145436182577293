import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../utils/api";
import {
  FaStar,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaArrowLeft,
} from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { getBase64ImageSrc } from "../../../utils/imageUtils";

export default function AccountantProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [accountant, setAccountant] = useState([]);
  const [loading, setLoading] = useState(true);
  const { image } = location.state || {};

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.get(`/accountant/${id}`);
        setAccountant(response.data?.Accountant);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [id]);

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 font-semibold text-sm mb-6"
      >
        <FaArrowLeft />
        <span>Back</span>
      </button>

      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 sm:p-8 transition duration-300">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center">
          <div className="flex items-center space-x-4">
            <div className="relative w-28 h-28 rounded-full overflow-hidden shadow-lg">
              {image ? (
                <img
                  src={getBase64ImageSrc(image)}
                  alt={`${accountant.name}'s profile`}
                  className="object-cover w-full h-full"
                />
              ) : (
                <span className="w-full h-full rounded-full bg-gray-400 flex items-center justify-center text-4xl font-bold text-white">
                  {accountant.Details?.name?.charAt(0)}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <h1 className="text-3xl font-bold text-gray-800">
                {loading ? (
                  <Skeleton width={200} height={30} />
                ) : (
                  accountant?.Details?.name
                )}
              </h1>
              <p className="text-gray-600 text-sm">
                {loading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  accountant?.Details?.qualifications
                )}
              </p>
            </div>
          </div>

          <div className="mt-6 sm:mt-0">
            {loading ? (
              <Skeleton width={100} height={30} />
            ) : (
              <>
                <p className="text-2xl font-semibold text-green-600">
                  ₱{accountant?.Details?.price}
                </p>
                <p className="text-gray-500 text-sm">Per Hour</p>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 mt-6">
          {loading ? (
            <>
              <Skeleton width={250} height={20} />
              <Skeleton width={250} height={20} />
              <Skeleton width={250} height={20} />
            </>
          ) : (
            <>
              <div className="flex items-center space-x-2 text-gray-700">
                <FaPhoneAlt className="text-gray-500" />
                <p>{accountant?.Details?.phone}</p>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <FaEnvelope className="text-gray-500" />
                <p>{accountant?.Details?.user_email}</p>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <FaMapMarkerAlt className="text-gray-500" />
                <p>{accountant?.Details?.location}</p>
              </div>
            </>
          )}
        </div>

        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-800">Reviews</h2>
          {loading ? (
            <Skeleton width={400} height={150} count={3} />
          ) : accountant?.Reviews?.length > 0 ? (
            accountant.Reviews.map((review, index) => (
              <div
                key={index}
                className="mt-4 bg-gray-100 p-4 rounded-md shadow-sm"
              >
                <div className="flex items-center space-x-2">
                  <FaStar className="text-yellow-500" />
                  <p className="text-gray-700">{review.rate}/5</p>
                </div>
                <p className="text-gray-500 text-sm mt-2">
                  {new Date(review.rate_date).toLocaleDateString()}
                </p>
                <p className="text-gray-800 mt-2">{review.review}</p>
              </div>
            ))
          ) : (
            <p className="mt-2 text-gray-500">No reviews available</p>
          )}
        </div>
      </div>
    </div>
  );
}
