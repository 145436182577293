export default function EarlyBirdModal({ closeModal }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 transition-opacity duration-300">
      <div className="bg-white p-2 sm:p-4 lg:p-6 rounded-2xl shadow-2xl w-full max-w-xs sm:max-w-sm lg:max-w-md mx-2 sm:mx-4 relative transition-transform transform duration-300 scale-100">
        <button
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={closeModal}
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 sm:h-5 sm:w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-lg sm:text-xl font-bold text-green-600 mb-2 sm:mb-4">
          🤖 Early Bird Discount!
        </h2>

        <p className="text-gray-700 text-xs sm:text-sm leading-relaxed my-2">
          Don't miss out on our <strong>exclusive Early Bird Discount! </strong>
          Upgrade to our Premium Plan and save big:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="font-bold">$2 OFF on the Monthly Premium Plan</li>
          <li className="font-bold">20% OFF on the Annual Premium Plan</li>
        </ul>

        <div className="bg-green-100 p-2 sm:p-4 rounded-lg mb-2 sm:mb-3">
          <h3 className="text-xs sm:text-sm font-semibold text-green-600 mb-2">
            Why Go Premium?
          </h3>
          <ul className="list-disc list-inside text-gray-700 text-xs sm:text-sm">
            <li>
              Access <strong> all advanced features and tools</strong>
            </li>
            <li>
              Enjoy <strong>unlimited usage </strong> of our premium content
            </li>
            <li>
              Get the most out of your experience with{" "}
              <strong>full access</strong>
            </li>
          </ul>
        </div>

        <p className="text-xs sm:text-sm font-bold">
          ⏳ Hurry! Offer ends October 31, 2024.
        </p>
        <p className="text-gray-700 text-xs sm:text-sm leading-relaxed mb-2">
          After this date, prices will return to normal—don't miss out!
        </p>
        <p className="text-[5px] sm:text-[6px] mb-4">
          **Discounts are automatically applied upon checkout
        </p>

        <div className="flex justify-center">
          <button
            className="px-3 py-1 sm:px-4 sm:py-2 bg-green-500 text-white font-semibold text-xs sm:text-sm rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 focus:ring-opacity-50 transition-all duration-200"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
