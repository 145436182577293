import React, { useState, useEffect } from "react";
import SearchFilter from "./components/SearchFilter";
import AllAccountants from "./components/AllAccountants";
import api from "../../utils/api";
import Pagination from "./components/Pagination";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import FeaturedAccountants from "./components/FeaturedAccountants";

export default function FindAccountant() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");

  const [accountants, setAccountants] = useState([]);
  const [filteredAccountants, setFilteredAccountants] = useState([]);
  const [optionQualif, setOptionQualif] = useState([]);
  const [topAccountants, setTopAccountants] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [loading, setLoading] = useState(true);
  const [loadingId, setLoadingId] = useState(null);

  const updateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1280) {
      setItemsPerPage(24);
    } else if (screenWidth >= 1024) {
      setItemsPerPage(18);
    } else if (screenWidth >= 768) {
      setItemsPerPage(14);
    } else {
      setItemsPerPage(12);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  useEffect(() => {
    const fetchAcc = async () => {
      try {
        setLoading(true); // Start loading
        const response = await api.get("/accountant/all");
        setAccountants(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAcc();
  }, []);

  useEffect(() => {
    let filtered = accountants;

    if (filter) {
      filtered = filtered.filter(
        (accountant) => accountant.qualifications === filter
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(
        (accountant) =>
          accountant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          accountant.qualifications
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
    }

    if (sort === "desc") {
      filtered = filtered.sort((a, b) => b.level - a.level);
    } else if (sort === "asc") {
      filtered = filtered.sort((a, b) => a.level - b.level);
    }

    setFilteredAccountants(filtered);
    setCurrentPage(1);
  }, [searchTerm, filter, sort, accountants]);

  useEffect(() => {
    const uniqueQualifications = Array.from(
      new Set(accountants.map((accountant) => accountant.qualifications))
    );
    setOptionQualif(uniqueQualifications);
  }, [accountants]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAccountants = filteredAccountants.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredAccountants.length / itemsPerPage);

  const handleSelectAccountant = (id, image) => {
    setLoadingId(id);
    navigate(`/accountant/profile/${id}`, { state: { image } });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="self-start pt-2">
        <button
          onClick={() => navigate("/")}
          className="flex items-center ml-2 mb-2 px-2 py-2 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-200 focus:ring-2 focus:ring-gray-500"
        >
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          Back
        </button>
      </div>
      <SearchFilter
        optionQualif={optionQualif}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
      <FeaturedAccountants
        accountants={accountants}
        loading={loading}
        handleSelectAccountant={handleSelectAccountant}
        loadingId={loadingId}
      />

      {loading ? (
        <div
          className={`transition-all duration-300 ${
            loading ? "blur-sm opacity-50" : ""
          }`}
        >
          <AllAccountants
            currentAccountants={currentAccountants}
            handleSelectAccountant={handleSelectAccountant}
            loadingId={loadingId}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center md:text-left">
            All Accountants
          </h2>
          <AllAccountants
            currentAccountants={currentAccountants}
            handleSelectAccountant={handleSelectAccountant}
            loadingId={loadingId}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
}
