import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import Navbar from "../../components/NavBar/NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import { useMsal } from "@azure/msal-react";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../utils/AuthProvider";
import api from "../../utils/api";
import EarlyBird from "./EarlyBird";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { instance } = useMsal();
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleMicrosoftLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });

      const user = loginResponse.account;
      const result = await api.post(
        `/login/`,
        { username: user.username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(result.data);
      console.log(result.data.token);
      login(result.data.token);

      toast.success("Microsoft sign in successful!");
      navigate("/app");
    } catch (error) {
      console.error("Microsoft sign in error:", error);
      toast.error("Microsoft sign in failed. Please try again.");
    }
  };

  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });

  const onChangeForm = (label, event) => {
    setLoginForm({ ...loginForm, [label]: event.target.value });
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.post("/login", loginForm, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      setLoading(false);

      const { access_token } = response.data;
      login(access_token);

      toast.success("Login successfully!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.detail);
      } else if (error.request) {
        toast.error("No response from server. Please try again later.");
      } else {
        toast.error("Error in setting up the request");
      }
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg-gray-50 text-black min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow flex justify-center items-center p-8 bg-gray-200">
        <div className="w-full max-w-6xl flex flex-col lg:flex-row items-center justify-center bg-white p-8 rounded-xl shadow-xl">
          <div className="hidden lg:flex flex-1 items-center justify-center">
            <img
              src="babylon.png"
              alt="Babylon Logo"
              className="h-40 object-contain"
            />
          </div>

          {/* Form */}
          <div className="flex-2 w-full max-w-md p-4">
            <div className="text-center mb-8">
              <p className="text-green-600 text-2xl font-semibold">
                Log in to continue
              </p>
            </div>

            <form className="space-y-6" onSubmit={handleSignIn}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(event) => onChangeForm("username", event)}
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary focus:border-primary"
                  placeholder="Email"
                  required
                />
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <button
                  type="button"
                  className="absolute right-4 top-[2.375rem] text-gray-500 hover:text-gray-800"
                  onClick={togglePasswordVisibility}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={(event) => onChangeForm("password", event)}
                  className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary focus:border-primary"
                  placeholder="Password"
                  required
                />
                <div className="mt-2 text-right">
                  <Link
                    to="/email/forgot-password"
                    className="text-sm text-red-600 hover:underline"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-3 rounded-lg font-medium hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <Loader id="spinner" className="h-6 w-6 mx-auto" />
                  ) : (
                    "Sign In"
                  )}
                </button>
              </div>
            </form>

            <div className="flex items-center justify-center mt-6">
              <hr className="w-1/3 border-gray-300" />
              <p className="text-xs text-center text-gray-500 mx-3">
                or login with
              </p>
              <hr className="w-1/3 border-gray-300" />
            </div>

            <div className="mt-6 flex justify-center">
              <button
                onClick={handleMicrosoftLogin}
                className="flex items-center justify-center bg-gray-100 py-2 px-4 rounded-lg shadow-sm hover:bg-gray-200"
              >
                <FontAwesomeIcon icon={faMicrosoft} className="h-5 w-5 mr-2" />
                <span className="text-sm">Microsoft</span>
              </button>
            </div>

            <p className="mt-8 text-center text-sm text-gray-600">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-green-600 font-medium hover:underline"
              >
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}
