import { useState } from "react";
import { usePassToggle } from "../../hooks/usePassToggle";
import { useRegistration } from "./useRegistration";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import Turnstile from "react-turnstile";
import { ReactComponent as Loader } from "../../assets/loader.svg";

export default function UserForm({
  openModal,
  openTermsOfServiceModal,
  handleMicrosoftLogin,
}) {
  const {
    email,
    industry,
    otherIndustry,
    password,
    confirmPassword,
    firstName,
    lastName,
    companyName,
    jobTitle,
    loading,
    phoneNumber,
    countryOptions,
    country,
    selectedOption,
    otherOptionText,

    handleClearSelection,
    handleCountryChange,
    setOtherOptionText,
    setEmail,
    setPassword,
    setConfirmPassword,
    setFirstName,
    setLastName,
    setCompanyName,
    setJobTitle,
    handleIndustryChange,
    handleSubmit,
    setOtherIndustry,
    handlePhoneChange,
    handleOptionChange,
  } = useRegistration();
  const {
    showPassword,
    showConfirmPassword,
    togglePasswordVisibility,
    toggleConfirmPasswordVisibility,
  } = usePassToggle();

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            required
            type="text"
            id="firstName"
            placeholder="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            required
            type="text"
            id="lastName"
            placeholder="Last Name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email Address
        </label>
        <input
          required
          type="email"
          id="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
        <div className="relative">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            required
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 pt-6 flex items-center text-sm leading-5"
            onClick={togglePasswordVisibility}
            tabIndex={-1}
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </button>
        </div>
        <div className="relative">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Confirm Password
          </label>
          <input
            required
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 pt-6 flex items-center text-sm leading-5"
            onClick={toggleConfirmPasswordVisibility}
            tabIndex={-1}
          >
            <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
          </button>
        </div>
      </div>
      <div className="mt-6">
        <label
          htmlFor="company"
          className="block text-sm font-medium text-gray-700"
        >
          Company (Optional)
        </label>
        <input
          type="text"
          id="company"
          name="company"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
        />
      </div>
      <div className="mt-6">
        <label
          htmlFor="jobTitle"
          className="block text-sm font-medium text-gray-700"
        >
          Job Title (Optional)
        </label>
        <input
          type="text"
          id="jobTitle"
          name="jobTitle"
          placeholder="Job Title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
        />
      </div>
      <div className="mt-6">
        <label
          htmlFor="industry"
          className="block text-sm font-medium text-gray-700"
        >
          Industry (Optional)
        </label>
        <select
          id="industry"
          name="industry"
          value={industry}
          onChange={handleIndustryChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          style={{ color: !industry ? "gray" : "black" }}
        >
          <option value="" disabled hidden>
            Enter your industry
          </option>
          <option value="">-- Clear Selection --</option>
          <option value="technology">Technology</option>
          <option value="finance">Finance</option>
          <option value="healthcare">Healthcare</option>
          <option value="education">Education</option>
          <option value="retail">Retail</option>
          <option value="other">Other</option>
        </select>
        {industry === "other" && (
          <input
            type="text"
            id="otherIndustry"
            name="otherIndustry"
            value={otherIndustry}
            onChange={(e) => setOtherIndustry(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
            placeholder="Enter your industry"
          />
        )}
      </div>
      <div className="mt-6 w-full max-w-full">
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Phone Number (Optional)
        </label>
        <div className="relative w-full max-w-full">
          <PhoneInput
            inputProps={{
              id: "phoneNumber",
              name: "phoneNumber",
              placeholder: "Enter phone number",
            }}
            country={"ph"}
            value={phoneNumber}
            onChange={handlePhoneChange}
            countryCodeEditable={false}
            containerClass="w-full max-w-full"
            inputClass="mt-1 block w-full max-w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
        </div>
      </div>

      <div className="mt-6">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700"
        >
          Country (Optional)
        </label>
        <Select
          id="country"
          name="country"
          options={[{ value: "", label: "None" }, ...countryOptions]}
          value={country}
          onChange={handleCountryChange}
          className="mt-1"
        />
      </div>
      <div className="mt-6">
        <div className="mt-6 flex items-center justify-start">
          <label className="block text-sm font-medium text-gray-700">
            How did you hear about us? (Optional)
          </label>
          <p
            // type="button"
            onClick={handleClearSelection}
            className="ml-2 px-2 text-xs text-red-400 cursor-pointer "
          >
            Clear Selection
          </p>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <input
              checked={selectedOption === "option1"}
              onChange={handleOptionChange}
              value="option1"
              name="option"
              type="radio"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-700">
              Search Engine (Google, Bing, etc.)
            </span>
          </label>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <input
              checked={selectedOption === "option2"}
              onChange={handleOptionChange}
              value="option2"
              name="option"
              type="radio"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-700">
              Recommended by friend or colleague
            </span>
          </label>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <input
              checked={selectedOption === "option3"}
              onChange={handleOptionChange}
              value="option3"
              name="option"
              type="radio"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-700">
              Social Media
            </span>
          </label>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <input
              checked={selectedOption === "option4"}
              onChange={handleOptionChange}
              value="option4"
              name="option"
              type="radio"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-700">
              Blog or publication
            </span>
          </label>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <input
              checked={selectedOption === "option5"}
              onChange={handleOptionChange}
              value="option5"
              name="option"
              type="radio"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <span className="ml-2 block text-sm text-gray-700">Others</span>
          </label>
        </div>
        {selectedOption === "option5" && (
          <input
            type="text"
            id="otherOptionText"
            name="otherOptionText"
            value={otherOptionText}
            onChange={(e) => setOtherOptionText(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2"
            placeholder="Please specify"
          />
        )}
      </div>

      <div className="mt-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Cloudflare CAPTCHA
        </label>
        <Turnstile
          sitekey="0x4AAAAAAAgngc33G6MdXehD"
          className="w-full"
          theme="light"
        />
      </div>

      <div className="mt-6">
        <label className="flex items-center">
          <input
            required
            type="checkbox"
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="ml-2 text-sm text-gray-600">
            I have read and agree to the{" "}
            <button
              type="button"
              onClick={openModal}
              className="text-indigo-600 hover:underline"
            >
              Privacy Policy
            </button>{" "}
            and{" "}
            <button
              type="button"
              onClick={openTermsOfServiceModal}
              className="text-indigo-600 hover:underline"
            >
              Terms of Service
            </button>
            .
          </span>
        </label>
      </div>
      <div className="mt-2 flex items-center">
        <input
          name="newsletterSubscription"
          type="checkbox"
          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <label
          htmlFor="newsletterSubscription"
          className="ml-2 block text-sm text-gray-700"
        >
          I want to get Newsletter Subscription
        </label>
      </div>
      <div className="mt-8">
        <button
          type="submit"
          className="w-full bg-green-500 text-white py-3 rounded-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
          disabled={loading}
        >
          {loading ? (
            <Loader id="spinner" className="h-6 w-6 mx-auto" />
          ) : (
            "Sign Up"
          )}
        </button>
      </div>

      <div className="mt-6 sm:mt-8 flex items-center justify-center">
        <hr className="w-1/2 border-t border-gray-500" />
        <p className="text-xs text-center sm:text-sm text-gray-600 px-2 sm:px-4">
          or sign up with
        </p>
        <hr className="w-1/2 border-t border-gray-500" />
      </div>
      <div className="flex justify-center sm:space-x-4 mt-2">
        <button
          onClick={handleMicrosoftLogin}
          className="flex items-center px-3 sm:px-5 py-2 sm:py-3 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 text-xs sm:text-lg"
        >
          <FontAwesomeIcon
            icon={faMicrosoft}
            className="h-5 sm:h-6 w-5 sm:w-6 mr-2 sm:mr-3"
          />
          <span className="text-gray-700">Microsoft</span>
        </button>
      </div>
    </form>
  );
}
