import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const blogsRef = useRef(null);

  const getLinkClasses = (path, isExternal = false) => {
    return currentPath === path && !isExternal
      ? "flex text-green-500 font-bold"
      : 'flex text-black hover:text-gray-600 focus:outline-none relative no-underline cursor-pointer before:content-[""] before:bg-green-400 before:absolute before:left-0 before:bottom-0 before:w-full before:h-1 before:pt-1 before:transform before:origin-right before:scale-x-0 before:transition-transform before:duration-200 before:ease-in hover:before:transform hover:before:origin-left hover:before:scale-x-100 focus:before:transform focus:before:origin-left focus:before:scale-x-100';
  };

  useEffect(() => {
    const handleWindowFocus = () => {
      if (blogsRef.current) {
        blogsRef.current.blur();
      }
    };

    window.addEventListener("focus", handleWindowFocus);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  return (
    <nav className="bg-white p-4 shadow-lg z-50 relative">
      <div className=" flex justify-between items-center">
        <div className="flex items-center space-x-4 sm:space-x-6 lg:space-x-8">
          <Link to="/">
            <img
              src="/babylon.png"
              alt="BETH Logo"
              className="h-12 mr-4 sm:mr-6 lg:mr-8"
            />
          </Link>
          <ul className="hidden lg:flex flex-wrap space-x-4 lg:space-x-6 ml-2 sm:ml-4 lg:ml-6">
            <li>
              <Link to="/" className={getLinkClasses("/")}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/pricing" className={getLinkClasses("/pricing")}>
                Pricing
              </Link>
            </li>
            <li>
              <Link to="/about-us" className={getLinkClasses("/about-us")}>
                About Us
              </Link>
            </li>
            <li>
              <a
                href="https://babylon2k.org/blogs/"
                target="_blank"
                rel="noreferrer"
                className={getLinkClasses("/blogs", true)}
                ref={blogsRef}
              >
                Blogs
              </a>
            </li>
            <li>
              <Link to="/accountant" className={getLinkClasses("/accountant")}>
                Find an Accountant
              </Link>
            </li>
          </ul>
        </div>

        <div className="hidden lg:flex space-x-4">
          <Link
            to="/signin"
            className="text-sm border border-green-600 text-green-600 rounded text-center px-4 py-2 hover:bg-gray-200 transition-colors duration-300"
          >
            Sign In
          </Link>
          <Link
            to="/signup"
            className="text-sm border border-green-600 bg-green-500 text-white rounded text-center px-4 py-2 hover:bg-green-600 transition-colors duration-300"
          >
            Sign Up
          </Link>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-4 lg:hidden">
          <button
            className="text-black hover:text-gray-400 focus:outline-none"
            onClick={() => setShowMenu(!showMenu)}
            title="Ellipsis Menu Bar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {showMenu ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      {showMenu && (
        <div className="lg:hidden absolute top-16 right-0 left-0 bg-white shadow-md rounded-md">
          <ul className="flex flex-col space-y-2">
            <li className="px-4 py-2">
              <Link to="/" className={getLinkClasses("/")}>
                Home
              </Link>
              <Link to="/about-us" className={getLinkClasses("/about-us")}>
                About Us
              </Link>
              <Link to="/pricing" className={getLinkClasses("/pricing")}>
                Pricing
              </Link>
              <a
                href="https://babylon2k.org/blogs/"
                rel="noreferrer"
                target="_blank"
                className={getLinkClasses("/blogs")}
              >
                Blogs
              </a>
              <Link to="/accountant" className={getLinkClasses("/accountant")}>
                Find an Accountant
              </Link>
            </li>
            <li className="py-2 mx-2 flex flex-col sm:flex-row gap-y-2 sm:gap-x-2 justify-center items-center">
              <Link
                to="/signin"
                className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-400 w-full text-center"
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="bg-green-500 text-white px-2 py-2 rounded hover:bg-green-400 w-full text-center"
              >
                Sign Up
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}
