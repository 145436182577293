import React from "react";
import AccountantCard from "./AccountantCard";

const AllAccountants = ({
  currentAccountants,
  handleSelectAccountant,
  loadingId,
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      {currentAccountants.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full">
          {currentAccountants.map((accountant, index) => (
            <AccountantCard
              key={index}
              accountant={accountant}
              handleSelectAccountant={handleSelectAccountant}
              loadingId={loadingId}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AllAccountants;
