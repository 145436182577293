import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  // Determine the range of visible pages
  const visiblePages = 5;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  const endPage = Math.min(totalPages, startPage + visiblePages - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center items-center space-x-2 my-4">
      {/* Previous Button */}
      <button
        className="px-3 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:opacity-50"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {startPage > 1 && (
        <button className="px-3 py-2 bg-gray-200 rounded-lg">...</button>
      )}
      {pageNumbers.map((page) => (
        <button
          key={page}
          className={`px-3 py-2 rounded-lg ${
            currentPage === page
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
      {endPage < totalPages && (
        <button className="px-3 py-2 bg-gray-200 rounded-lg">...</button>
      )}

      {/* Next Button */}
      <button
        className="px-3 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 disabled:opacity-50"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
