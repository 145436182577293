import React, { useState, useEffect, useRef } from "react";
import api from "../../utils/api";
import "../../styles/loader.css";

const predefinedQuestions = [
  {
    question: "What is B.E.T.H.?",
    answer:
      "B.E.T.H. is the ultimate artificial intelligence platform designed specifically for professionals in auditing, tax, and accounting.",
  },
  {
    question: "How can B.E.T.H. help me?",
    answer:
      "B.E.T.H. provides instant, relevant answers to complex financial and compliance questions. Here's how B.E.T.H. can help:\n\n- Real-time analysis of financial data.\n- Comprehensive tax compliance support.\n- Automated audit processes.",
  },
  {
    question: "Who uses B.E.T.H.?",
    answer:
      "B.E.T.H. is used by auditors, tax consultants, accountants, and professionals in related fields.",
  },
];

const Chatbot = ({ openModal }) => {
  const [messages, setMessages] = useState([
    { text: "Hi there! How can I assist you today?", sender: "bot" },
  ]);
  const [input, setInput] = useState("");
  const [currentInputWordCount, setCurrentInputWordCount] = useState(0);
  const [responseParts, setResponseParts] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [typedText, setTypedText] = useState("");
  const [isTypewriterRunning, setIsTypewriterRunning] = useState(false);
  const [ongoingMessage, setOngoingMessage] = useState(false);

  const chatRef = useRef(null);

  const countWords = (text) => text.trim().split(/\s+/).filter(Boolean).length;

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInput(text);
    setCurrentInputWordCount(countWords(text));
  };

  const fetchResponse = async (message) => {
    try {
      const response = await api.get(`chat/response/sample`, {
        params: { prompt: message },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      return "Sorry, something went wrong. Please try again.";
    }
  };

  const handleSendMessage = async (message) => {
    if (isTypewriterRunning || !message.trim() || countWords(message) > 20)
      return;

    const userMessage = { text: message, sender: "user" };
    setMessages([...messages, userMessage]);
    setInput("");
    setCurrentInputWordCount(0);
    setOngoingMessage(true);
    setIsTypewriterRunning(true);

    const botResponse = await fetchResponse(message);
    const parts = botResponse.split("\n\n");
    setResponseParts(parts);
    setCurrentPartIndex(0);
    setTypedText("");
    setOngoingMessage(false);
    openModal();
  };

  const handleSend = () => handleSendMessage(input);

  const handlePredefinedQuestionClick = (question) =>
    handleSendMessage(question);

  useEffect(() => {
    if (responseParts.length > 0 && currentPartIndex < responseParts.length) {
      const timer = setTimeout(() => {
        const currentPart = responseParts[currentPartIndex];
        if (typedText.length < currentPart.length) {
          setTypedText(currentPart.slice(0, typedText.length + 1));
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: currentPart, sender: "bot" },
          ]);
          setCurrentPartIndex(currentPartIndex + 1);
          setTypedText("");
          if (currentPartIndex + 1 === responseParts.length) {
            setIsTypewriterRunning(false);
          }
        }
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [responseParts, currentPartIndex, typedText]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const renderMessageText = (text) => {
    const parts = text.split("\n").map((part, index) => {
      const boldParts = part
        .split(/(\*\*.*?\*\*)/g)
        .map((subPart, subIndex) => {
          if (subPart.startsWith("**") && subPart.endsWith("**")) {
            return (
              <span
                key={subIndex}
                style={{ fontSize: "1.25rem", fontWeight: "bold" }}
              >
                {subPart.slice(2, -2)}
              </span>
            );
          }
          return <span key={subIndex}>{subPart}</span>;
        });
      return <p key={index}>{boldParts}</p>;
    });

    return parts;
  };

  return (
    <div className="relative w-full sm:w-1/2 bg-gray-800 rounded-lg shadow-lg flex items-center justify-center text-white">
      <div className="bg-gray-700 p-4 sm:p-6 rounded-lg shadow-lg w-full">
        <h2 className="text-2xl text-white font-extrabold mb-4 text-center">
          Chat with B.E.T.H.
        </h2>
        <div
          ref={chatRef}
          className="mb-4 h-56 sm:h-64 border-2 border-white overflow-y-auto p-2 sm:p-4 rounded-lg bg-white"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`text-md mb-2 ${
                message.sender === "bot" ? "text-left" : "text-right"
              }`}
            >
              <div
                className={`inline-block p-2 ${
                  message.sender === "bot"
                    ? "text-black"
                    : "text-black bg-gray-200 mb-4 rounded-lg"
                }`}
              >
                {renderMessageText(message.text)}
              </div>
            </div>
          ))}

          {ongoingMessage && (
            <div className="text-md mb-2 text-left">
              <div className="inline-block p-4 rounded-lg bg-gray-200 text-white">
                <div className="bouncing-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          )}
          {isTypewriterRunning && !ongoingMessage && (
            <div className="text-md mb-2 text-left">
              <div className="inline-block p-2 rounded-lg text-black">
                {renderMessageText(typedText)}
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex flex-wrap justify-center">
          {predefinedQuestions.map((question, index) => (
            <div key={index} className="mr-2 mb-2">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
                onClick={() => handlePredefinedQuestionClick(question.question)}
                disabled={isTypewriterRunning}
              >
                {question.question}
              </button>
            </div>
          ))}
        </div> */}
        <div
          style={{ color: currentInputWordCount > 20 ? "red" : "white" }}
          className="text-right mt-2"
        >
          Word count: {currentInputWordCount}/20
        </div>
        <div className="flex flex-col sm:flex-row lg:flex-row mt-2">
          <input
            type="text"
            className="text-white bg-gray-500 p-2 md:p-4 border-2 border-gray-600 rounded-t-lg sm:rounded-l-lg sm:rounded-t-none sm:flex-1 focus:outline-none w-full sm:w-auto"
            placeholder="Type your message here.."
            value={input}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !isTypewriterRunning) handleSend();
            }}
            disabled={isTypewriterRunning}
          />
          <button
            className="bg-green-500 text-white p-2 md:p-4 border-2 border-gray-600 rounded-b-lg sm:rounded-r-lg sm:rounded-b-none sm:w-auto w-full sm:flex-none hover:bg-green-700"
            onClick={handleSend}
            disabled={isTypewriterRunning}
          >
            Send
          </button>
        </div>
        <div className="flex flex-col text-center text-sm text-gray-400 mt-3">
          Not satisfied with the answer?
          <a href="/pricing" className="text-md text-blue-400 hover:underline">
            Get Premium now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
