import React from "react";
import {
  FaSearch,
  FaFilter,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";

const SearchFilter = ({
  searchTerm,
  optionQualif,
  setSearchTerm,
  filter,
  setFilter,
  sort,
  setSort,
}) => {
  return (
    <div className="mb-8 w-full bg-white shadow-lg rounded-lg px-4 py-2">
      <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center md:text-left">
        Find an Accountant
      </h1>
      <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
        {/* Search Field */}
        <div className="relative w-full md:w-2/3">
          <input
            type="text"
            placeholder="Search by name or qualifications"
            className="border border-gray-300 p-4 w-full rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-green-400 pl-12"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500" />
        </div>

        {/* Filter Select */}
        <div className="relative w-full md:w-1/3">
          <select
            className="border border-gray-300 p-4 w-full rounded-lg text-gray-600 appearance-none focus:outline-none focus:ring-2 focus:ring-green-400 pl-12"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="">Filter by Qualifications</option>
            {optionQualif.map((qualification, index) => (
              <option key={index} value={qualification}>
                {qualification}
              </option>
            ))}
          </select>
          <FaFilter className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500" />
        </div>

        {/* Sort Select */}
        <div className="relative w-full md:w-1/3">
          <select
            className="border border-gray-300 p-4 w-full rounded-lg text-gray-600 appearance-none focus:outline-none focus:ring-2 focus:ring-green-400 pl-12"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="">Sort by Level</option>
            <option value="desc">Highest to Lowest</option>
            <option value="asc">Lowest to Highest</option>
          </select>
          {sort === "desc" ? (
            <FaSortAmountDown className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500" />
          ) : (
            <FaSortAmountUp className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
