import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Spinner from "../../../components/Spinner/Spinner";
import { getBase64ImageSrc } from "../../../utils/imageUtils";

const FeaturedAccountants = ({
  accountants,
  handleSelectAccountant,
  loadingId,
  loading,
}) => {
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    if (accountants.length > 0) {
      const randomized = [...accountants].sort(() => Math.random() - 0.5);
      const featuredAccountants = randomized.slice(0, 5);
      setFeatured(featuredAccountants);
    }
  }, [accountants]);

  const customRenderIndicator = (onClickHandler, isSelected, index, label) => {
    return (
      <li
        className={`inline-block mx-1 cursor-pointer transition-transform ${
          isSelected
            ? "w-4 h-4 bg-green-600 rounded-full transform scale-110"
            : "w-3 h-3 bg-gray-300 rounded-full"
        }`}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        aria-label={`${label} ${index + 1}`}
        tabIndex={0}
      />
    );
  };

  return (
    <div className="container mx-auto px-4 lg:px-12 py-10">
      <h2 className="text-4xl font-bold text-gray-800 mb-10 text-center">
        Featured Accountants
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <Carousel
          showThumbs={false}
          infiniteLoop
          autoPlay
          showStatus={false}
          showArrows={false}
          emulateTouch
          interval={4000}
          renderIndicator={customRenderIndicator}
        >
          {featured.map((accountant, index) => (
            <div key={index} className="px-6">
              <div className="flex flex-col items-center  p-10 bg-gradient-to-b from-green-700 via-white to-gray-200 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:scale-95">
                <div className="w-40 h-40 mb-6 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden shadow-lg border-4 border-white">
                  {accountant.profile_picture ? (
                    <img
                      src={getBase64ImageSrc(accountant.profile_picture)}
                      alt={`${accountant.name}'s profile`}
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <span className="text-5xl font-bold text-white bg-gray-700 w-full h-full rounded-full flex items-center justify-center">
                      {accountant.name.charAt(0)}
                    </span>
                  )}
                </div>

                <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                  {accountant.name}
                </h3>
                <p className="text-lg text-gray-700 mb-2">
                  {accountant.qualifications}
                </p>
                <p className="text-xl font-bold text-green-600 mb-2">
                  ₱{accountant.price}/hr
                </p>
                <p className="text-sm text-gray-500 mb-6">
                  {accountant.reviews || 0} reviews
                </p>

                <button
                  onClick={() =>
                    handleSelectAccountant(
                      accountant.accountant_id,
                      accountant.profile_picture
                    )
                  }
                  className={`w-3/4 py-4 rounded-full text-lg font-semibold text-white transition-all duration-300 transform hover:scale-105 ${
                    loadingId === accountant.accountant_id
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300"
                  }`}
                  disabled={loadingId === accountant.accountant_id}
                >
                  {loadingId === accountant.accountant_id
                    ? "Loading..."
                    : "Select Accountant"}
                </button>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default FeaturedAccountants;
