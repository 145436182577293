import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/AuthProvider";
import api from "../../../utils/api";
import { FaCrown } from "react-icons/fa";

const HeaderDashboard = ({
  userLevel,
  sidebarVisible,
  setSidebarVisible,
  dropdownOpen,
  setDropdownOpen,
  logout,
  openModal,
  wordCount,
}) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { token } = useAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleButton = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  return (
    <header className="bg-white py-1 px-4 flex flex-col">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          {!sidebarVisible && (
            <button
              className="text-black hover:text-gray-400 focus:outline-none mr-4"
              onClick={() => setSidebarVisible(!sidebarVisible)}
              title="Ellipsis Menu Bar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          )}
          <img className="h-12" src="/logobaby.png" alt="Logo" />
        </div>

        {!isSmallScreen ? (
          <div
            className={`flex justify-between items-center w-48 p-1 rounded-xl shadow-lg ${
              userLevel === "premium" || userLevel === "employee"
                ? "bg-gradient-to-r from-green-50 to-blue-50 border border-green-400"
                : "bg-gray-100"
            }`}
          >
            <div className="mr-5 text-black flex items-center gap-x-2">
              {userLevel === "premium" || userLevel === "employee" ? (
                <div className="flex items-center gap-x-2 text-xs font-semibold text-green-800">
                  <FaCrown size={20} />
                  <span className="ml-1">Premium Member</span>
                </div>
              ) : (
                <>
                  <img className="max-h-5" src="/coin.svg" alt="coin" />
                  <div className="relative w-24 h-6 bg-gray-200 rounded-lg overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-300"
                      style={{ width: `${(wordCount / 5000) * 100}%` }}
                    />
                    <span className="relative text-xs font-medium text-gray-700 flex justify-center items-center h-full">
                      {wordCount}/5000
                    </span>
                  </div>
                </>
              )}
            </div>

            <div
              className="flex items-center justify-center cursor-pointer h-8 w-8 rounded-full text-white bg-green-400 hover:bg-green-500 transition-colors duration-300"
              onClick={toggleButton}
              ref={buttonRef}
            >
              {user ? user.first_name.charAt(0) : "A"}
            </div>
          </div>
        ) : (
          <div
            className="flex items-center justify-center cursor-pointer h-8 w-8 aspect-square rounded-full text-white bg-green-400 hover:bg-green-500 transition-colors duration-300"
            onClick={toggleButton}
            ref={buttonRef}
          >
            {user ? user.first_name.charAt(0) : "A"}
          </div>
        )}

        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute right-2 top-12 w-48  bg-white border border-gray-300 rounded shadow-lg z-50"
          >
            <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
              <Link to="/user-page" className="w-full h-full block text-left">
                Profile
              </Link>
            </button>
            <button
              onClick={openModal}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              My Plan
            </button>
            <button
              onClick={logout}
              className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 w-full text-left"
            >
              Logout
            </button>
          </div>
        )}
      </div>

      {isSmallScreen && (
        <div
          className={`relative flex justify-start items-center p-2 rounded-xl w-full overflow-hidden border-2 ${
            userLevel == "premium" || userLevel == "employee"
              ? "bg-gradient-to-r from-blue-50 to-green-100"
              : "border-gray-300 bg-gray-100"
          } shadow-md`}
        >
          {userLevel === "free" && (
            <div
              className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-500 ease-in-out z-10"
              style={{ width: `${(wordCount / 5000) * 100}%` }}
            />
          )}

          {userLevel == "premium" || userLevel == "employee" ? (
            <div className="flex items-center gap-x-2 text-sm font-semibold text-green-800 relative z-20">
              <FaCrown size={16} />
              <span className="ml-1">Premium Member</span>
            </div>
          ) : (
            <div className="flex items-center gap-x-2 relative z-10">
              <img className="max-h-5" src="/coin.svg" alt="coin" />
              <span className="text-sm font-medium text-gray-800">
                {wordCount}/5000
              </span>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default HeaderDashboard;
